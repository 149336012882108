import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import moment from 'moment-timezone'
import {FC, useMemo} from 'react'
import {useForm} from 'react-hook-form'

import {CommentsCategory} from '../../../../../../shared/enums'
import {useDeleteComment} from '../../../../../../shared/hooks/api'
import {useAddScheduleComment} from '../../../../../../shared/hooks/api/comments/useAddScheduleComment'
import {useUpdateScheduleComment} from '../../../../../../shared/hooks/api/comments/useUpdateScheduleComment'
import {usePermission} from '../../../../../../shared/hooks/permissions/usePermission'
import {useAutoSaveValue} from '../../../../../../shared/hooks/useAutoSaveValue'
import {useCurrentTimeRounded} from '../../../../../../shared/hooks/useCurrentTimeRounded'
import {useUrlParam} from '../../../../../../shared/hooks/useUrlParam'
import {Comment, ScheduleItem} from '../../../../../../shared/interfaces/api'
import {PlannerDataScope} from '../../../../../../shared/interfaces/api/permissions'
import {CommentInput} from '../../../comments/CommentInput'

const checkScheduleCommentsDataScope = (dataScope: Partial<PlannerDataScope>) =>
  dataScope?.commentCategory === CommentsCategory.AssetOperationTimes ? true : false

interface ScheduleItemCommentSectionProps {
  comment: Comment | undefined
  scheduleItem: ScheduleItem
  timezoneId: string
}

export const ScheduleItemCommentSection: FC<ScheduleItemCommentSectionProps> = ({
  comment,
  timezoneId,
  scheduleItem
}) => {
  const plantCode = useUrlParam('plantCode')
  const nowRounded = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})

  // TODO abstract to useCommentsPermission hook
  const canEditComments = usePermission('EDIT_COMMENTS', checkScheduleCommentsDataScope)
  const canAddComments = usePermission('ADD_COMMENTS', checkScheduleCommentsDataScope)
  const canDeleteComments = usePermission('DELETE_COMMENTS', checkScheduleCommentsDataScope)

  const {addScheduleComment, isAddCommentLoading} = useAddScheduleComment()
  const {updateScheduleComment} = useUpdateScheduleComment()
  const {mutate: deleteComment, isLoading: isDeletingComment} = useDeleteComment()

  const commentInitialState: Pick<Comment, 'value'> = {value: comment?.value ?? ''}
  const {
    register,
    formState: {isDirty},
    reset,
    handleSubmit,
    watch
  } = useForm<typeof commentInitialState>({
    defaultValues: commentInitialState
  })

  const commentId = comment?.commentId

  const onSubmit = useMemo(
    () =>
      handleSubmit((data: typeof commentInitialState) => {
        if (commentId) {
          return updateScheduleComment({
            plantCode,
            commentsCategoryInstanceId: scheduleItem.id,
            commentId,
            commentDto: data
          })
        }
        return addScheduleComment(
          {
            plantCode,
            commentsCategoryInstanceId: scheduleItem.id,
            commentDto: data
          },
          {
            onSuccess: (comment) => {
              reset({value: comment.value})
            }
          }
        )
      }),
    [
      handleSubmit,
      addScheduleComment,
      plantCode,
      scheduleItem.id,
      updateScheduleComment,
      commentId,
      reset
    ]
  )

  const handleDeleteComment = commentId
    ? () => {
        deleteComment(
          {
            plantCode,
            commentsCategoryInstanceId: scheduleItem.id,
            commentId,
            commentsCategory: CommentsCategory.AssetOperationTimes
          },
          {
            onError: () => {
              reset(undefined, {keepValues: true})
            },
            onSuccess: () => {
              reset({value: ''})
            }
          }
        )
      }
    : undefined

  // we need to watch this fields to rerun the autosave effect
  const currentCommentValue = watch('value')

  useAutoSaveValue({
    currentValue: currentCommentValue,
    shouldSave: isDirty && currentCommentValue !== '',
    saveFn: onSubmit
  })

  const isHistoryItem = moment.utc(scheduleItem.end).isBefore(nowRounded)

  const isCommentInputDisabled =
    !(canEditComments && canAddComments) ||
    isDeletingComment ||
    isAddCommentLoading ||
    isHistoryItem
  const isDeleteButtonDisabled = !canDeleteComments || isDeletingComment
  return (
    <CommentInput
      testId="schedule_item_comment_input"
      inputProps={{...register('value')}}
      comment={comment}
      currentCommentValue={currentCommentValue}
      timezoneId={timezoneId}
      onDelete={isHistoryItem ? undefined : handleDeleteComment}
      isCommentInputDisabled={isCommentInputDisabled}
      isDeleteButtonDisabled={isDeleteButtonDisabled}
    />
  )
}
